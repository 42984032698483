<!--
// nuxt-ui/components/Shared/twitch/EmbedTwitch.vue
-->
<script setup lang="ts">
type Props = {
  channel: string;
  width: number | string;
  height: number | string;
  isLive: boolean;
};

const props = defineProps<Props>();

const loadTwitchScript = () => {
  return new Promise((resolve, reject) => {
    if (window.Twitch) {
      resolve();
      return;
    }

    const existingScript = document.querySelector('script[src="https://embed.twitch.tv/embed/v1.js"]');
    if (existingScript) {
      existingScript.onload = () => resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://embed.twitch.tv/embed/v1.js";
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Failed to load Twitch script"));
    document.body.appendChild(script);
  });
};

const removeTwitchEmbed = () => {
  const embedDiv = document.getElementById("embedContainer");
  if (embedDiv) {
    embedDiv.innerHTML = "";
  }
};

const initTwitchEmbed = () => {
  if (window.Twitch && window.Twitch.Player) {
    new window.Twitch.Player("embedContainer", {
      width: props.width,
      height: props.height,
      channel: props.channel,
      muted: true,
    });
  }
};

watch(
  () => props.isLive,
  newValue => {
    if (newValue === false) {
      removeTwitchEmbed();
    } else {
      initTwitchEmbed();
    }
  }
);

onMounted(async () => {
  try {
    await loadTwitchScript();
    if (props.isLive) {
      initTwitchEmbed();
    }
  } catch (error) {
    console.error("Error loading Twitch Embed:", error);
  }
});
</script>

<template>
  <div id="embedContainer"></div>
</template>

<style scoped></style>
